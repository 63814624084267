<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{ 'image-loading': !bg1ImageLoaded }">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" :removeMenu="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-8vh h-md-0"></div>
      <section v-if="bg1ImageLoaded" class="landing_view h-md-100vh">
        <div class="row d-flex align-items-start justify-content-end">
          <div class="col-12 col-md-10">
            <div class="row">
              <div class="col-12 mt-5">
                <h1 class="large-text fnt-tny text-uppercase text-center">
                  {{ $t('mom shop - ai powered digital store creator') }}
                </h1>
                <h2 class="large-text-2 text-capitalize hide_for_mobile">
                  {{ $t('Boost Your Online Presence: Get Your Business Digital with MOM Shop App') }}.
                </h2>
              </div>
              <div class="col-12">
                <p>
                  {{ $t('AI-powered Simple, flexible, and powerful Order management system with integrated Cloud POS, HRMS, and Delivery Management Software') }}.
                </p>
              </div>
              <div class="col-12">
                <b-link 
                  class="custom-design custom-design-black px-md-5 font-weight-bold tra-black-bg" 
                  :href="`https://www.jvzoo.com/b/109545/398905/99`"
                >
                  <img src="https://i.jvzoo.com/109545/398905/99" alt="MOM Shop App" border="0" width='1' height='1' /> {{ $t('BUY NOW') }}
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-center text-uppercase text-light">
            {{ $t('Your All-in-One Solution for Seamless Shopping and Business Growth') }}.
          </h1>
          <h2 class="large-text-2">{{ $t('MOM Shop App is your gateway to expanding your retail business in the digital age') }}. {{ $t('We offer an opportunity to empower shop owners with the tools and features needed for success in the world of e-commerce') }}.</h2>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4 mb-1 mb-md-0">
              <div class="row d-flex justify-content-center">
                <div class="col-10">
                  <b-img :src="`${$apiUrl}/images/landing/Website/MOM Shop.webp`" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div>
                <p>{{ $t('Instant Visibility: Join our platform and instantly showcase your products to a vast online audience') }}. {{ $t('With MOM Shop App, your store gains immediate visibility, ensuring that potential customers can discover your offerings effortlessly') }}.</p>
                <p>{{ $t('Effortless Shopping: Simplify the shopping experience for your customers with our intuitive interface') }}. {{ $t('Thanks to our user-friendly design, shoppers can browse and purchase your products in just a few clicks') }}.</p>
                <p>{{ $t('Local & Global Reach: Expand your customer base beyond your local area') }}. {{ $t('MOM Shop App allows you to reach local customers and expand your business globally') }}. {{ $t('Connect with potential clients from around the world and tap into new markets') }}.</p>
                <p>{{ $t('Secure Payments: Concerned about payment security? Rest assured, the MOM Shop App ensures secure and hassle-free transactions for both you and your customers') }}. {{ $t('Funds are directly deposited into your company account, providing peace of mind') }}.</p>
                <p>{{ $t('Data-Driven Growth: Harness the power of data to drive your business forward') }}. {{ $t('MOM Shop App provides powerful analytics tools that allow you to make informed decisions') }}. {{ $t('Track performance, analyze trends, and refine your product offerings for maximum profitability') }}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Create an online profile in just 60 seconds, in 3 easy steps') }}
          </h1>
        </div>
      </div>
      <div class="h-md-3vh"></div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row landing_items_view">
            <div class="col-12 col-md-4 landing_item" v-for="(item, ik) in landingItems" :key="`ik_${ik}`">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 col-md-8 mx-auto">
                      <b-img
                        fluid
                        :src="`${$apiUrl}/images/landing/shop/${item.img}`"
                        :alt="item.imgAlt"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-px-5">
                  <b-link class="custom-design" href="#">{{ item.title }}</b-link>
                  <span v-for="(itemp, ikp) in item.text" :key="`itp_${ikp}_${ik}`">
                    <p class="text-muted" v-html="itemp"></p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Solution for Every Business') }}
          </h1>
        </div>
      </div>
      <div class="row landing_grids">
        <div class="col-12 col-md-4 grid_items" v-for="(menu, index) in servicesList" :key="`l_card_${index}`">
          <div class="ai_widget_img">
            <b-img fluid :src="`${$apiUrl}/images/landing-shop/${menu.image}`" class="home_img" />
          </div>
          <b-link class="ai_widget_label custom-design mx-auto" href="#">{{ $t(menu.title) }}</b-link>
        </div>
      </div>
    </section>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-2 fnt-tny text-uppercase text-center">
            {{ $t('Why choose MOM to create your online store?') }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10 mx-auto text-center">
          <ol class="why_mom_listing" start="1">
            <li>
              {{ $t("0% Commission: Say goodbye to hefty commission fees") }}. {{ $t("With MOM Shop, you keep 100% of your hard-earned profits") }}. {{ $t("We believe in supporting your growth without cutting into your earnings") }}.
            </li>
            <li>
              {{ $t("Analytics & Reports: Gain valuable insights into your business's performance with our robust analytics and reporting tools") }}. {{ $t("Track key metrics, understand customer behavior, and make data-driven decisions to optimize your operations and increase profitability") }}.
            </li>
            <li>
              {{ $t("Delivery Management Software: Seamlessly handle your order deliveries with our built-in delivery management system (DMS)") }}. {{ $t("Efficiently dispatch orders, track delivery progress, and provide your customers with a reliable delivery experience") }}.
            </li>
            <li>
              {{ $t("Table Bookings: Expand your services beyond traditional e-commerce with table bookings") }}. {{ $t("Whether you're in the restaurant or hospitality industry, MOM Shop supports table reservations to enhance your customer experience") }}.
            </li>
            <li>
              {{ $t("Direct Payment to Bank: We understand the importance of quick and hassle-free payments") }}. {{ $t("MOM Shop allows you to receive payments directly to your bank account, ensuring your cash flow is always smooth") }}.
            </li>
            <li>
              {{ $t("Order Dashboard: Stay organized and in control with our intuitive order dashboard") }}. {{ $t("Monitor incoming orders, manage customer requests, and streamline your order fulfillment process") }}.
            </li>
            <li>
              {{ $t("Promotion and Loyalty Programs: Boost your sales and customer retention with attractive promotions and loyalty programs") }}. {{ $t("MOM Shop makes it easy to create and manage these initiatives to keep your customers coming back for more") }}.
            </li>
            <li>
              {{ $t("Cloud POS (Point of Sale): Simplify transactions with the Cloud POS system") }}. {{ $t("Accept payments seamlessly, generate receipts, and manage finances efficiently from one platform or connect to your existing POS like Petpooja") }}.
            </li>
            <li>
              {{ $t("Automated Invoicing: Say goodbye to manual invoicing tasks") }}. {{ $t("MOM Shop automates the invoicing process, saving you time and reducing the risk of errors") }}.
            </li>
            <li>
              {{ $t("Global Payment Support: Expand your business globally with confidence") }}. {{ $t("MOM Shop supports a wide range of global payment methods like Stripe, PayPal, Phonepe, Razorpay, Flutterwave making it easy for customers from around the world to purchase from your store") }}.
            </li>
            <li>
              {{ $t("HRMS : Onboard and manage a global workforce") }}. {{ $t("Review employee performance Manage attendance, shifts, timesheets, and leave") }}
            </li>
          </ol>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat, imgLoad } from '@/utilities'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
    SubscriptionList
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  data: () => ({
    bg1ImageLoaded: false
  }),
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'storeSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    servicesList() {
      return [
        {
          title: 'Restaurants & Cloud Kitchen',
          image: 'restaurants-cloud-kitchen.webp',
          url: '/user/store/list/restaurant',
        },
        {
          title: 'Electronics',
          image: 'electronics.webp',
          url: '/user/store/list/grocery',
        },
        {
          title: 'Fashion & Accessories',
          image: 'fashion-accessories.webp',
          url: '/user/services/subcategories/list/salon',
        },
        {
          title: 'Groceries & Mini Marts',
          image: 'groceries-mini-marts.webp',
          url: '/user/store/list/market',
        },
        {
          title: 'Home Decor',
          image: 'home-decor.webp',
          url: '/user/store/list/gifts',
        },
        {
          title: 'Gifts & Stationery',
          image: 'gifts-stationery.webp',
          url: '/user/services/subcategories/list/repairs',
        },
      ]
    },
    landingItems: {
      get(){
        return [
          {
            img: 'step1.png',
            imgAlt: "Step 1",
            title: this.$t('STEP 1'),
            text: [
              `<strong class="text-light">
                ${this.$t('Register your store')}
              </strong>
              - ${this.$t('Enter your store name, logo, address & other details and get your custom store link ready in 60 seconds!')}`,
              `${this.$t('Psss')}.... ${this.$t('We will let you in on a secret, your store will also be powered by an built in (DMS) delivery management software')}`
            ]
          },
          {
            img: 'step2.png',
            imgAlt: "Step 2",
            title: this.$t('STEP 2'),
            text: [
              `<strong class="text-light">
                ${this.$t('Add your products ')}
              </strong>
              - ${this.$t('Create your category first and start adding your products, set prices, add color and sizes (if necessary) and allow your customers to check instantly')}.`
            ]
          },
          {
            img: 'step3.png',
            imgAlt: "Step 3",
            title: this.$t('STEP 3'),
            text: [
              `<strong class="text-light">
                ${this.$t('Start Selling ')}
              </strong>
              - ${this.$t('This is where it all begins')}. ${this.$t('Your store is ready for the world')}. ${this.$t('Share the link with all your friends & family via Email, WhatsApp or Facebook and start taking orders')}.`
            ]
          }
        ]
      }
    },
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
  },
  methods: {
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    isEmpty, dateTimeFormat,
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-shop-registration'
      })
    }
  },
  async mounted() {
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/main2.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })
      if (!localStorage.getItem('shopSelectedCountryId')) {
        try {
          const _ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
          const ipInfo = await _ipInfo.json()
          if(!isEmpty(ipInfo?.location)){
            if(!this.isSubscriptionCountryLoaded){
              await this.getCountries().then(data => {
                if (data._statusCode === 200) {
                    const countries = data.responseData
                    const isCountryExist = countries.find(x => x.country_name == ipInfo.location.country.name)
                    if (isCountryExist) {
                      localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
                    }
                }
              })
            }else{
              const countries = this.countries
              const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
              if (isCountryExist) {
                localStorage.setItem('shopSelectedCountryId', isCountryExist.id)
              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (!this.isSubscriptionLoaded) {
          await this.getSubscriptionPackages()
      }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-image: url('~@/assets/images/bg-images/main2.webp');
  background-color: #040612;
  padding-bottom: 1.5rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
